<script>
    import { getContext } from 'svelte';
    import CardModalPopup from './CardModalPopup.svelte';
    let confirmTerms = true;
    export let contentData = [];
    export let mainCurency = null;
    export let summary = null;
    export let summaryOnce = null;
    export let startgebuehr = null;
    export let packagesData = null;
    const { open } = getContext('simple-modal');
    const showSurprise = () => open(CardModalPopup, { contentData: contentData, mainCurency: mainCurency, summary: summary, summaryOnce: summaryOnce, startgebuehr: startgebuehr, packagesData: packagesData });
    function createPackagesString(packagesData) {
        const allPackages = [...packagesData.monthly, ...packagesData.once];

        const packageStrings = allPackages.map(pkg => {
            return `${pkg.item}: ${pkg.title}`;
        });

        return packageStrings.join(', ');
    }
    function trackVehicleOptions(packagesData) {
        const packagesString = createPackagesString(packagesData);
        console.log(packagesString);
        if (window.gtag) {
            try {
                gtag('event', 'abosite_fahrzeug_options', {
                    'event_category': 'Interaction',
                    'event_label': (contentData?.attributes?.Titel || 'k. A.') + ' : ' + packagesString,
                    'event_value': packagesString,
                    'page_title': document.title,
                    'page_url': window.location.href
                });
              
            } catch (error) {
                console.error('Fehler beim Senden des Events an Google Analytics:', error);
            }
        }
    }
</script>
<label for="confirm"><input type="checkbox" id="confirmTerms" on:change={() => {
    confirmTerms =! confirmTerms; 
}}> <a href="/agb" target="_blank" noreferrer>AGBs gelesen und akzeptiert.</a></label>  
<p><button id="inquiryForm" on:click={showSurprise} on:mouseup={trackVehicleOptions(packagesData)} disabled={confirmTerms}>Anfrage senden</button></p>

<style>
    #confirmTerms {
        margin: -5px 4px 0px 0px;
    }
    #inquiryForm {
        font-family: "BeresaSans-Demi", sans-serif;
        font-size: 16px;
        line-height: 24px;
        min-width: 180px;
        background-color: #FF005F;
        color: #ffffff;
        cursor: pointer;
        margin: 12px 0;
        padding: 12px;
        border-radius: 12px;
    }
    #inquiryForm:disabled {
        background-color: #cccccc;
        color: #ffffff;
        cursor: not-allowed;
    }
</style>