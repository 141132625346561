<script>
    import {
        salesPriceData,
        salesPriceDataOnce
    } from "../../utilities/salesPriceData";
    import Device from 'svelte-device-info'
    import { fade, crossfade, scale } from "svelte/transition";
    import Modal from 'svelte-simple-modal';
    import CardImageSlider from "./CardImageSlider.svelte";
    import CardOptionButton from "./CardOptionButton.svelte";
    import CardRuntimeButton from "./CardRuntimeButton.svelte";
    import CardDeductibleReducedButton from "./CardDeductibleReducedButton.svelte";
    import CardMilesageButton from "./CardMilesageButton.svelte";
    import CardModal from './CardModal.svelte';
    
   // import { pushToArray, sumArray } from "../../utilities/salesPriceData";
 
    import isset from "isset-php";
    export let getValideValue = null;
    export let getValideList = null;
    export let detailOverview = 0;
    export let einheitVerbrauch = null;
    export let bezeichnungVebrauch = null;
    export let contentData = [];
    export let mainCurency = 279;
    export let summary = 0;
    export let summaryOnce = 0;
    export let packagesData = null;
    export let startgebuehr = 0;

    function isSmart(str) {
      return /smart/.test(str);
    }

    function isEKlasse(str) {
      return /E-Klasse/.test(str);
    }

    function isTwingo(str) {
      return /Twingo/.test(str);
    }

    function sbPrice(str) {
      return (str === true) ? 750 : 500;
    }

    function resetSummary() {
      	window.history.pushState({}, document.title, window.location.pathname);
    }

    const kWhtoPS = (kWh) => {
        const kW = kWh;
        const PS = kW * 1.3596;
        return Math.round(Math.round(PS * 100) / 100);
    }

    function resetSumary() {
        $salesPriceData = [];
        $salesPriceDataOnce = []; 
    }

    const [send, receive] = crossfade({
      duration: 200,
      fallback: scale,
    });
  
    let p = 0;
    let o = 0;
    $: summary = parseFloat(p);
    $: summaryOnce = parseFloat(o);
    $: packagesData = packagesData;

    $: mainCurency = (getValideValue(contentData?.attributes, 'Basispreis') ?? 0);
    $: startgebuehr = (getValideValue(contentData?.attributes, 'Startgebuehr') === '-' ? 0 : getValideValue(contentData?.attributes, 'Startgebuehr'));
    
    const CO2_Klasse_entl_Batterie = (contentData?.attributes?.Emissionen?.CO2_Klasse_entl_Batterie ?? '-').split(':')[0].trim();
    const CO2_Klasse_gew_Kombiniert = (contentData?.attributes?.Emissionen?.CO2_Klasse_gew_Kombiniert ?? '-').split(':')[0].trim();
</script>
<section class="pb-20 block">
    <div class="lg:pt-6 pt-6 px-2" transition:fade>
      <div class="mb-8 sm:px-4 text-left">
        <strong class="btn-back text-left" on:mousedown={() => { detailOverview = 0; resetSummary(); }}>
          <span class="icon"> &#x21e6;&nbsp; </span> Zurück zur Übersicht
        </strong>
      </div>
      <div class="flex flex-wrap pt-4">
        <div class="align-top pt-6 w-full lg:w-6/12" style="padding-right: 15px;">
          {#if Device.isMobile === true}
          <div class="top-0 mb-8">
            <h1 class="text-xl pricetag font-semibold text-left">
              {contentData?.attributes?.Titel}
            </h1>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="lg:w-4/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">BASISPREIS&nbsp;</h2>
              <h2 class="text-xl pricetag font-semibold text-left">zzgl. Startgebühr&nbsp;</h2>
            </div>
            <div class="lg:w-2/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">{mainCurency} €</h2>
              <h2 class="text-xl pricetag font-semibold text-left">{startgebuehr} € <strong>(einmalig)</strong></h2>
            </div>
          </div>
          {/if}
          <div class="top-0">
            <div class="bg-white">
              <CardImageSlider items = {getValideValue(contentData?.attributes?.Bildergalerie, 'data')} />
            </div>
          </div>
          <div class="flex-col">
            <div class="flex mb-8">
              <div class="w-full lg:w-6/12 mb-8 ">
                <div class="pt-6 text-left">
                  <p><strong>Basisinformationen</strong></p>
                  <p class="mt-2">Leistung<br />{getValideValue(contentData?.attributes?.Informationen, 'Leistung')} {einheitVerbrauch(getValideValue(contentData?.attributes?.Informationen, 'Antrieb')) === 'l' ? 'kW ' : 'kW'} ({kWhtoPS(getValideValue(contentData?.attributes?.Informationen, 'Leistung'))} PS)</p>
                  <p class="mt-6">Antrieb<br />{getValideValue(contentData?.attributes?.Informationen , 'Antrieb')}</p>
                  {#if getValideValue(contentData?.attributes?.Emissionen, 'Batteriekapa') >= 0}
                    <p class="mt-6">Batteriekapazität<br />{getValideValue(contentData?.attributes?.Emissionen, 'Batteriekapa')} kWh</p>
                  {/if}
                  <p class="mt-6">Anzahl Türen<br />{getValideValue(contentData?.attributes?.Informationen, 'Turen')}</p>
                  <p class="mt-6">Farbe<br />{getValideValue(contentData?.attributes?.Informationen, 'Farbe')}</p>
                </div>
                <div class="flex min-w-0 break-words">
                  <div class="mt-6 lg:w-12/12 item text-left">
                    <strong>Verbrauch und Emissionen</strong>
                    
                    <p class="mt-2 box-underline">
                        <small>Energieverbrauch {getValideValue(contentData?.attributes?.Informationen, 'Antrieb')?.includes('Hybrid') ? 'gewichtet, kombiniert' : 'kombiniert'}</small><br />
                        {
                          (getValideValue(contentData?.attributes?.Informationen, 'Antrieb') === 'Elektrisch') ? (getValideValue(contentData?.attributes?.Emissionen, 'Energieverbrauch_kwh') + ' kWh/100km') :  
                          ( 
                            (getValideValue(contentData?.attributes?.Informationen, 'Antrieb')?.includes('Hybrid')) ? 
                              getValideValue(contentData?.attributes?.Emissionen, 'Energieverbrauch_kwh') + ' kWh/100km und '+ getValideValue(contentData?.attributes?.Emissionen, 'Kraftstoffverbrauch_kombiniert') + ' ' + bezeichnungVebrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb')) : 
                              getValideValue(contentData?.attributes?.Emissionen, 'Kraftstoffverbrauch_kombiniert') + ' ' + bezeichnungVebrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb'))
                          )                        
                         }
                    </p> 
                    {#if getValideValue(contentData?.attributes?.Emissionen, 'Kraftstoffverbrauch_ent_Bat') !== '-'}
                    <p class="mt-2 box-underline">
                      <small>Kraftstoffverbrauch bei entl. Batterie</small><br />{getValideValue(contentData?.attributes?.Emissionen, 'Kraftstoffverbrauch_ent_Bat')} l/100km
                    </p>
                    {/if}
                    {#if getValideValue(contentData?.attributes?.Emissionen, 'CO2_Emission_kombiniert')  !== '-'}
                    <p class="mt-6 box-underline">
                        <small>CO₂-Emission {getValideValue(contentData?.attributes?.Informationen, 'Antrieb')?.includes('Hybrid') ? 'gewichtet, kombiniert' : 'kombiniert'}</small><br />{getValideValue(contentData?.attributes?.Emissionen, 'CO2_Emission_kombiniert')} g/km
                    </p>
                    {/if}
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 mb-8">
                <div class="pt-6 text-left">
                  <strong>Ausstattungshighlights*</strong>
                    {#if getValideList(contentData?.attributes,'Ausstattung').length > 0}
                        <ul class="mt-2 mb-4 p-12 text-blueGray-500">
                        {#each getValideList(contentData?.attributes,'Ausstattung') as [k, v], index}  
                            {#if v === true}
                            <li class="mt-0">&#x2713; {k.replaceAll("_", " ")}</li>
                            {/if}
                        {/each}
                        </ul>
                    {:else}
                        <p><small>Es liegen aktuell keine Informationen vor.</small></p>
                    {/if}
                </div>
              </div>
            </div>
            <b>CO₂-Klasse</b><br />
            {#if !Device.isMobile === true}
              {#if isset(() => contentData?.attributes?.Emissionen.CO2_Klasse_entl_Batterie)}
              <div class="flex min-w-0 break-words mb-8">
                <div class="w-full lg:w-6/12 item text-left">
                {#if isset(() => contentData?.attributes?.Emissionen.CO2_Klasse_gew_Kombiniert)}
                  <small>gewichtet, kombiniert</small><br />
                  <div class="mt-2 mb-4"><img class="eff" src="https://beresa.machs-mit-marketing.de/assets/img/eff-{CO2_Klasse_gew_Kombiniert}.svg" alt="{contentData?.attributes?.Emissionen.CO2_Klasse_gew_Kombiniert}" /></div>
                {/if}
                </div>
                <div class="w-full lg:w-6/12 item text-left">
                  <small>bei entladener Batterie</small><br />
                  <div class="mt-2 mb-4"><img class="eff" src="https://beresa.machs-mit-marketing.de/assets/img/bat-{CO2_Klasse_entl_Batterie}.svg" alt="{contentData?.attributes?.Emissionen.CO2_Klasse_entl_Batterie}" /></div>
                </div>
              </div>
              {:else}
                  {#if isset(() => contentData?.attributes?.Emissionen.CO2_Klasse_gew_Kombiniert)}
                  <div class="mt-2 mb-4"><img class="eff" src="https://beresa.machs-mit-marketing.de/assets/img/eff-{CO2_Klasse_gew_Kombiniert}.svg" alt="{contentData?.attributes?.Emissionen.CO2_Klasse_gew_Kombiniert}" /></div>
                  {:else}
                  -
                  {/if}
              {/if}
            {/if}
          </div>
        </div>
        <div class="align-top pt-6 w-full lg:w-6/12 px-0 text-left">
          {#if !Device.isMobile === true}
          <div class="top-0">
            <h1 class="text-xl pricetag font-semibold text-left">
              {contentData?.attributes?.Titel}
            </h1>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-4/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">BASISPREIS&nbsp;</h2>
              <h2 class="text-xl pricetag font-semibold text-left">zzgl. Startgebühr&nbsp;</h2>
            </div>
            
            <div class="pt-6 lg:w-4/12 item text-left">
                <div class="text-xl pricetag font-semibold text-left">&nbsp;</div>
            </div>
            
            <div class="pt-6 lg:w-2/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">{mainCurency} €</h2>
              <h2 class="text-xl pricetag font-semibold text-left">{startgebuehr} € <strong>(einmalig)</strong></h2>
            </div>
          </div>
          {:else}
            <b><small>CO₂-Effizienz</small></b><br />
            {#if isset(() => contentData?.attributes?.Emissionen.CO2_Klasse_entl_Batterie)}
            <div class="flex min-w-0 break-words mb-8">
              <div class="w-full lg:w-6/12 item text-left">
              {#if isset(() => contentData?.attributes?.Emissionen.Effizienzklasse)}
                <small>gewichtet kombiniert<br /></small>
                <div class="mt-2 mb-4"><img class="eff" src="https://beresa.machs-mit-marketing.de/assets/img/eff-{getValideValue(contentData?.attributes?.Emissionen,'Effizienzklasse')}.svg" alt="{contentData?.attributes?.Emissionen.Energieklasse}" /></div>
              {/if}
              </div>
              <div class="w-full lg:w-6/12 item text-left">
                <small>bei entladener Batterie</small><br />
                <div class="mt-2 mb-4"><img class="eff" src="https://beresa.machs-mit-marketing.de/assets/img/bat-{CO2_Klasse_entl_Batterie}.svg" alt="{contentData?.attributes?.Emissionen.CO2_Klasse_entl_Batterie}" /></div>
              </div>
            </div>
            {:else}
                {#if isset(() => contentData?.attributes?.Emissionen.Effizienzklasse)}
                <div class="mt-2 mb-4"><img class="eff" src="https://beresa.machs-mit-marketing.de/assets/img/eff-{getValideValue(contentData?.attributes?.Emissionen,'Effizienzklasse')}.svg" alt="{contentData?.attributes?.Emissionen.Energieklasse}" /></div>
                {:else}
                -
                {/if}
            {/if}
          {/if}
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">Kilometerpaket</h2>
          </div>
          <div class="flex min-w-0 break-words">
            <div class="pt-6 lg:w-12/12 item text-left">
              {#if contentData?.attributes?.kilometerpakets?.data?.length > 0}
              <CardMilesageButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={contentData?.attributes?.kilometerpakets?.data}
              />
              {/if}
            </div>
          </div>
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">Laufzeit</h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              {#if contentData?.attributes?.laufzeits?.data?.length > 0}
              <CardRuntimeButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={contentData?.attributes?.laufzeits?.data}
              />
              {/if}
            </div>
          </div>
          <div class="top-0 mb-4">
            <hr />
          </div>
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">
              Zusatzfahrer <strong>(Optional)</strong>
            </h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summaryOnce={o}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "once",
                    elem: "3",
                    name: "kein Zusatzfahrer",
                    value: "0",
                    option: "",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                  {
                    type: "once",
                    elem: "3",
                    name: "Zusatzfahrer",
                    value: "179",
                    option: "179 € einmalig",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                ]}
              />
            </div>
          </div>
          
          {#if contentData?.attributes?.sb_reduzierungs?.data?.length > 0}
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">
              SB-Reduziert <strong>(Optional)</strong>
            </h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardDeductibleReducedButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={contentData?.attributes?.sb_reduzierungs?.data}
              />
            </div>
          </div>
          {/if}
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">
              Auslandsfahrten <strong>(Optional)</strong>
            </h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "month",
                    elem: "4",
                    name: "keine Auslandsfahrten",
                    value: "0",
                    option: "",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                  {
                    type: "month",
                    elem: "4",
                    name: "Auslandsfahrten",
                    value: "10",
                    option: "10 € / Monat",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                ]}
              />
            </div>
          </div>
          {#if (contentData?.attributes?.Fahrzeugoptionen?.Ladekabelmiete === true)}
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">
              Ladekabelmiete <strong>(Optional)</strong>
            </h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "month",
                    elem: "5",
                    name: "kein Ladekabel",
                    value: "0",
                    option: "",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                  {
                    type: "month",
                    elem: "5",
                    name: "Ladekabelmiete",
                    value: "10",
                    option: "10 € / Monat",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                ]}
              />
            </div>
          </div>
          {/if}
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">Lieferung <strong>(Optional)</strong></h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summaryOnce={o}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "once",
                    elem: "6",
                    name: "keine Lieferung",
                    value: "0",
                    option: "",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 100 km",
                    value: "349",
                    option: "349 € einmalig",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 200 km",
                    value: "399",
                    option: "399 € einmalig",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 300 km",
                    value: "499",
                    option: "499 € einmalig",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 500 km",
                    value: "699",
                    option: "699 € einmalig",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 700 km",
                    value: "749",
                    option: "749 € einmalig",
                    hide: [],
                    title: contentData?.attributes?.Titel
                  }
                ]}
              />
            </div>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 w-full lg:w-6/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">GESAMTSUMME<br /><strong>inklusive MwSt.</strong></h2>
            </div>
            <div class="pt-6 lg:w-4/12 item text-right">
              <h2 class="text-xl pricetag font-semibold text-right"><strong>einmalig</strong>&nbsp;</h2>
              <h2 class="text-xl pricetag font-semibold text-right"><strong>monatlich</strong>&nbsp;</h2>
            </div>
            <div class="pt-6 lg:w-2/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">{startgebuehr + summaryOnce} €</h2>
              <h2 class="text-xl pricetag font-semibold text-left">{mainCurency + summary} €</h2>
            </div>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <Modal><CardModal {contentData} {summary} {summaryOnce} {startgebuehr} {mainCurency} {packagesData}/></Modal>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:w-12/12 px-4 text-left">
        <div class="top-0"><small>*Abweichungen vom abgebildeten Fahrzeug und aufgelisteten Ausstattungen möglich.<br />**Bei einer flexiblen Laufzeit gilt eine beidseitige Kündigungsfrist von vier Wochen zum Monatsende. Wird der Vertrag nicht gekündigt, verlängert sich das Angebot um einen Monat.</small></div>
      </div>
    </div>
</section>

<style>
@media (max-width: 1024px) {
  .item, .block {
    width: 100% !important;
  }
}

h1 {
    font-size: 1.75rem;
    margin: 0 1rem 0 0;
    display: flex;
    align-items: center;
}

img.eff {
    width: 320px;
    height: auto;
    margin: -2.25rem -0.4rem;
    background-color: transparent;
    padding: 0;
}

.font-semibold {
  color: #000000 !important;  
}

.box-underline {
    border-bottom: 1px solid #CCCCCC;
}

.pricetag {
    font-family: "BeresaSans-Demi", sans-serif;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #000000 !important;
    cursor: pointer;  
}
h1.pricetag {
    font-size: 22px !important;
    line-height: 24px !important;
    color: #FF005F !important; 
}
h2.pricetag {
    font-size: 18px !important;
    line-height: 22px !important;
    color: #FF005F !important;
}

.btn-back {
    font-family: "BeresaSans-Demi", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #6d97a1 !important;
    cursor: pointer !important;
}

.flex {
    display: flex;
}

</style>