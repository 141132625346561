<script>
    import { onMount, onDestroy } from "svelte";
    import { createEventDispatcher } from "svelte";
    let firma = "";
    let vorname = "";
    let nachname = "";
    let strasse = "";
    let geburtsdatum = "";
    let plz = "";
    let ort = "";
    let telefonnummer = "";
    let email = "";
    let bemerkung = "";
    let gutscheincode = "";
    
    let firmaError = "";
    let vornameError = "";
    let nachnameError = "";
    let geburtsdatumError = "";
    let strasseError = "";
    let plzError = "";
    let ortError = "";
    let telefonnummerError = "";
    let emailError = "";
    let showMessage = false;

    export let contentData = [];
    export let mainCurency = 279;
    export let summary = null;
    export let summaryOnce = null;
    export let startgebuehr = 0;
    export let packagesData = null;
       
    let formSubmitted = false; // Abbruch-Tracking nur wenn nicht abgeschickt
    let DuSie  = (window.location.hostname === 'www.wuddi.de') ? ['deine','dir'] : ['Ihre','Ihnen'];

    // === Formular-Validierung ===
    function validateFirma() { firmaError = firma ? "" : "Pflichtfeld"; }
    function validateVorname() { vornameError = vorname ? "" : "Pflichtfeld"; }
    function validateNachname() { nachnameError = nachname ? "" : "Pflichtfeld"; }
    function validateGeburtsdatum() { geburtsdatumError = geburtsdatum ? "" : "Pflichtfeld"; }
    function validateStrasse() { strasseError = strasse ? "" : "Pflichtfeld"; }
    function validatePlz() { plzError = plz ? "" : "Pflichtfeld"; }
    function validateOrt() { ortError = ort ? "" : "Pflichtfeld"; }
    function validateTelefonnummer() { telefonnummerError = telefonnummer ? "" : "Pflichtfeld"; }
    function validateEmail() { emailError = email.includes("@") ? "" : "Ungültige E-Mail"; }

    async function submitForm() {
        const formData = {
            contentData,
            packagesData,
            'basispreis': mainCurency,
            'startgebuehr': startgebuehr,
            'summary': summary,
            'summaryOnce': summaryOnce,
            'firma': firma,
            'vorname': vorname,
            'nachname': nachname,
            'strasse': strasse,
            'geburtsdatum': geburtsdatum,
            'plz': plz,
            'ort': ort,
            'telefon': telefonnummer,
            'email': email,
            'bemerkung': bemerkung,
            'gutscheincode': gutscheincode,
            'currentDomain': window.location.hostname
        };

        trackVehicleInterestSend(contentData, mainCurency, startgebuehr, summary, summaryOnce, gutscheincode);

        let project = (window.location.hostname.includes("wuddi")) ? 'wuddi' : 'beresa';

        const response = await fetch(`https://beresa.machs-mit-marketing.de/abosite/${project}/send/mail`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
        });

        if (response.ok) {
            formSubmitted = true;
            showMessage = true;
        }
    }

    function handleSubmit() {
        validateFirma();
        validateVorname();
        validateNachname();
        validateGeburtsdatum();
        validateStrasse();
        validatePlz();
        validateOrt();
        validateTelefonnummer();
        validateEmail();

        if (
            !firmaError && !vornameError && !nachnameError && !geburtsdatumError &&
            !strasseError && !plzError && !ortError && !telefonnummerError && !emailError
        ) {
            submitForm();
        }
    }

    // === Tracking-Funktion für Abbruch ===
    function trackFormAbort() {
        if (!formSubmitted) {
            trackVehicleInterestClose(contentData, mainCurency, startgebuehr, summary, summaryOnce, gutscheincode);
        }
    }

    // === Events für Tracking ===
    function handleBeforeUnload(event) {
        trackFormAbort();
    }

    function handleEscape(event) {
        if (event.key === "Escape") {
            trackFormAbort();
        }
    }

    onMount(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        document.addEventListener("keydown", handleEscape);
    });

    onDestroy(() => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        document.removeEventListener("keydown", handleEscape);
        trackFormAbort(); // Falls das Modal z. B. geschlossen wird
    });

    // === Mock-Tracking-Funktionen ===
    function trackVehicleInterestSend(data, currency, start, sum, sumOnce, code) {
        console.log("📩 Form gesendet:", { data, currency, start, sum, sumOnce, code });
    }

    function trackVehicleInterestClose(data, currency, start, sum, sumOnce, code) {
        console.log("🚨 Form abgebrochen:", { data, currency, start, sum, sumOnce, code });
    }
</script>

{#if showMessage}
    <strong>Vielen Dank für {DuSie[0]} Anfrage!</strong>
    <p class="text-green-600 font-bold">Wir werden uns so schnell wie möglich bei {DuSie[1]} melden!</p>
{:else}
<form on:submit|preventDefault={handleSubmit}>
    <div class="mb-4">
        <strong>Anfrage für folgendes Fahrzeug: {contentData?.attributes?.Titel}*</strong>
        <input type="hidden" bind:value={contentData} />
    </div>
    <div class="mb-4">
        <label for="firma" class="block text-gray-700 text-sm font-bold mb-2"
            >Firma</label
        >
        <input
            type="text"
            bind:value={firma}
            id="firma"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
    </div>
    <div class="flex flex-col md:flex-row md:space-x-4">
        <div class="flex flex-col mb-4 md:w-6/12">
            <label
                for="vorname"
                class="block text-gray-700 text-sm font-bold mb-2"
                >Vorname*</label
            >
            <input
                type="text"
                bind:value={vorname}
                id="vorname"
                required
                class={vornameError
                    ? "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-500"
                    : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
            />
            {vornameError && { vornameError }}
        </div>
        <div class="flex flex-col mb-4 md:w-6/12">
            <label
                for="nachname"
                class="block text-gray-700 text-sm font-bold mb-2"
                >Nachname*</label
            >
            <input
                type="text"
                bind:value={nachname}
                id="nachname"
                required
                class={nachnameError
                    ? "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-500"
                    : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
            />
            {nachnameError && { nachnameError }}
        </div>
    </div>
    <div class="mb-4">
        <label
            for="geburtsdatum"
            class="block text-gray-700 text-sm font-bold mb-2"
            >Geburtsdatum*</label
        >
        <div class="relative">
            <input
                type="date"
                id="geburtsdatum"
                required
                bind:value={geburtsdatum}
                class={geburtsdatumError
                    ? "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-500"
                    : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
                placeholder="TT.MM.JJJJ"
            />
            {geburtsdatumError && { geburtsdatumError }}
            <span class="absolute top-0 right-0 px-3 py-2">
                <svg
                    class="fill-current h-4 w-4 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    ><path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path
                        fill-rule="evenodd"
                        d="M19 10a9 9 0 11-18 0 9 9 0 0118 0zm-9-7a1 1 0 011 1v1a1 1 0 11-2 0V4a1 1 0 011-1zm0 12a1 1 0 01-1-1v-7a1 1 0 112 0v7a1 1 0 01-1 1z"
                        clip-rule="evenodd"
                    /></svg
                >
            </span>
        </div>
    </div>
    <div class="mb-4">
        <label for="strasse" class="block text-gray-700 text-sm font-bold mb-2"
            >Straße*</label
        >
        <input
            type="text"
            bind:value={strasse}
            id="strasse"
            required
            class={strasseError
                ? "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-500"
                : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
        />
        {strasseError && { strasseError }}
    </div>
    <div class="flex flex-col md:flex-row md:space-x-4">
        <div class="flex flex-col mb-4 md:w-2/12">
            <label for="plz" class="block text-gray-700 text-sm font-bold mb-2"
                >PLZ*</label
            >
            <input
                type="text"
                bind:value={plz}
                id="plz"
                required
                class={plzError
                    ? "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-500"
                    : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
            />
            {plzError && { plzError }}
        </div>
        <div class="flex flex-col mb-4 md:w-8/12">
            <label for="ort" class="block text-gray-700 text-sm font-bold mb-2"
                >Ort*</label
            >
            <input
                type="text"
                bind:value={ort}
                id="ort"
                required
                class={ortError
                    ? "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-500"
                    : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
            />
            {ortError && { ortError }}
        </div>
    </div>
    <div class="flex flex-col md:flex-row md:space-x-4">
        <div class="flex flex-col mb-4 md:w-6/12">
            <label
                for="telefonnummer"
                class="block text-gray-700 text-sm font-bold mb-2"
                >Telefon-/Mobilnummer*</label
            >
            <input
                type="text"
                bind:value={telefonnummer}
                id="telefonnummer"
                required
                class="{telefonnummerError
                    ? 'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-500'
                    : 'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'}required"
                on:input={() => validateTelefonnummer()}
            />
            {telefonnummerError && { telefonnummerError }}
        </div>
        <div class="flex flex-col mb-4 md:w-6/12">
            <label
                for="email"
                class="block text-gray-700 text-sm font-bold mb-2"
                >E-Mail*</label
            >
            <input
                type="email"
                bind:value={email}
                id="email"
                required
                class={emailError
                    ? "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-500"
                    : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
            />
            {emailError && { emailError }}
        </div>
    </div>
    <div class="mb-4">
        <div class="flex flex-col mb-4 md:w-6/12">
            <label
                for="gutscheincode"
                class="block text-gray-700 text-sm font-bold mb-2"
                >Gutscheincode<br /><small>(Der Code wird in der Angebotserstellung berücksichtig)</small></label
            >
            <input
                type="text"
                bind:value={gutscheincode}
                id="gutscheincode"
            />
        </div>
    </div>
    <div class="mb-4">
        <label for="bemerkung" class="block text-gray-700 text-sm font-bold mb-2"
            >Bemerkung</label
        >
        <textarea
            bind:value={bemerkung}
            id="bemerkung"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
    </div>
    <div class="flex items-center justify-between">
        <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
        >
            Anfrage senden
        </button>
    </div>
</form>
{/if}

<style>
    button {
        font-family: "BeresaSans-Demi", sans-serif;
        font-size: 16px;
        line-height: 24px;
        min-width: 180px;
        background-color: #FF005F;
        color: #ffffff;
        cursor: pointer;
        margin: 12px 0;
        padding: 12px;
        border-radius: 12px;
    }
    button:hover {
        color: #444444;
        background-color: #FF005F45;
        border: 2px #FF005F !important;
    }
</style>
