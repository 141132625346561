<script>
  // core components
  import Grid from "./CardGridMain.svelte";
  export let filtered = {};
  let data = [];
  let promise = Promise.resolve([]);

  //Neu
  async function getPostData() {
    let result;
    const urlSearchParams = new URLSearchParams(window.location.search);
    let filterValue = urlSearchParams.get('Filter');
    filterValue = ((filterValue) ? filterValue : (window.location.hash.slice(1) ? window.location.hash.slice(1) : 'all'))
    if(filterValue === "beresa-abosite") {
      filterValue = "all";
    }
    return (await self
      .fetch("https://beresa.machs-mit-marketing.de/abosite/teaser/filter/"+filterValue)
      .then((res) => {
        if (res.ok) {
          result = res.json();
        } else {
          throw new Error(data);
        }
        return result;
      }));
  }

  $: filtered = (() => {
      promise = getPostData();
      return promise;
  })();

  $: data = filtered;

</script>

{#await promise}
  <h2 class="text-center">Fahrzeugdaten werden geladen...</h2>
{:then filtered}
  <Grid data={data.length >= 0 ? data : filtered.data} />
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}