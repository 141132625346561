<script>
  import { loop_guard } from "svelte/internal";
  import {
    salesPriceData,
    salesPriceDataOnce,
  } from "../../utilities/salesPriceData";

  export let summary = 0;
  export let summaryOnce = 0;
  export let packagesData;
  export let items = {};

  let current = 0;
  function selectOption(type, id, i, value, title) {
    let itemName = "";
    switch (id) {
      case "1":
        itemName = "Kilometerpaket";
        break;

      case "2":
        itemName = "Laufzeitpaket";
        break;

      case "3":
        itemName = "Zusatzfahrer";
        break;

      case "4":
        itemName = "Auslandsfahrten";
        break;

      case "5":
        itemName = "Ladekabel";
        break;

      case "6":
        itemName = "Lieferung";
        break;

      default:
        break;
    }
    current = i;
    let elements = {
      id: id,
      item: itemName,
      value: value,
      title: title
    };
    if (type == "month") {
      $salesPriceData = pushToArray($salesPriceData, id, elements);
      summary = parseFloat(sumArray($salesPriceData));
    } else if (type == "once") {
      $salesPriceDataOnce = pushToArray($salesPriceDataOnce, id, elements);
      summaryOnce = parseFloat(sumArray($salesPriceDataOnce));
    }
    packagesData = { monthly: $salesPriceData, once: $salesPriceDataOnce };
    console.log(packagesData);
  }

  function sumArray(array) {
    let sum = 0;
    array.forEach((item) => {
      sum += parseFloat(item.value);
    });
    return parseFloat(sum);
  }

  function pushToArray(mainArr, id, value) {
    mainArr
      .filter((x) => x.id === id)
      .forEach((x) => mainArr.splice(mainArr.indexOf(x), 1));
    mainArr.push(value);
    return mainArr;
  }
</script>

{#each items as item, i}
  <button
    class:active={current === i}
    on:mousedown={() => selectOption("month", "1", i, item?.attributes?.Paketpreis, item?.attributes?.Paketname)}
    ><b>{item?.attributes?.Paketname}</b><br />{item?.attributes?.Paketpreis} € / Monat
  </button>
{/each}

<style>
  button {
    display: block;
    width: 200px;
    height: 80px;
    background-color: #f1f1f1;
    border-radius: 4px;
    float: left;
    padding: 4px;
    margin-right: 10px;
    border: 2px #ffffff;
    margin-bottom: 10px;
  }
  button:hover {
    color: #444444;
    background-color: #FF005F45;
    border: 2px #FF005F !important;
  }

  .active {
    border: 2px #FF005F;
    color: #ffffff;
    background-color: #FF005F;
  }
</style>
